body,
html,
#root,
.App {
  background-color: white;
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
  font-family: "Neucha", sans-serif;
  padding: 25px;
}

.App > div {
  max-width: 900px;
  margin: auto;
}

.App:focus {
  outline: none;
}
