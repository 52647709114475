.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: white;
}

.flex-left {
  margin: 20px;
  font-size: calc(8px + 2vmin);
  display: flex;
  align-items: left;
  flex-direction: column;
}

.flexDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flexDivHorizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.font-yellow {
  color: yellow;
}

.font-red {
  color: red;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.font-mediumseagreen {
  color: red;
}

.normalfont {
  margin: 20px;
  font-size: calc(10px + 2vmin);
}

.smallfont {
  margin: 20px;
  font-size: calc(8px + 2vmin);
}

.smallfont-margin5 {
  font-size: calc(8px + 2vmin);
  margin: 5px;
}

.margin20 {
  margin: 20px;
}

.margin5 {
  margin: 5px;
}

.margin10 {
  margin: 10px;
}

.input-box {
  height: 30px;
  padding: 5px;
}

.redFont {
  color: red;
}

.greenFont {
  color: green;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
