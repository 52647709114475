.WednesdayApp-logo {
  animation: App-logo-spin infinite 5s linear;
  height: 10vh;
}

.WednesdayApp-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0.1 1 10%;
  margin-bottom: 150px;
  color: #fff;
}

.flexDivHorizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
