.WednesdayApp {
  /* height: 100%; */
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.WednesdayApp a {
  text-decoration: none;
  color: #ff6600;
}

.WednesdayApp a:hover {
  text-decoration: none;
}

.WednesdayApp-content {
  display: flex;
  /* flex: 0.8 0 80%; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.margin5 {
  margin: 14px;
}
