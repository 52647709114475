.Game-Restart > button {
  border-radius: 8px;
  padding: 6px 12px;
  background: linear-gradient(to bottom, #ff6600 0%, #ff6600 100%);
  font-weight: 300;
  font-size: .6em;
  border: none;
  color: #fff;
  cursor: pointer;
}

.Game-Restart > button:focus {
  outline: none;
}

.Game-Restart > button:active {
  box-shadow: inset 0px 1px 3px 2px rgba(0, 0, 0, 0.4);
	cursor: pointer;
}

.Game-Restart > button:hover {
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to bottom, #000 0%, #000 100%);
}
